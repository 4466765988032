@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";
@import "react-taggables-input/dist/tags.css";
@import 'react-phone-number-input/style.css';

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.cover {
  object-fit: cover;
}

#js-licensing {
  display: none;
}

section {
  font-family: "Inter", sans-serif;
}

.dot-blue {
  background: #327EFA;
}

.img-overlay {
  object-fit: cover;
  height: 100vh;
  z-index: 0;
}

.blend-color {
  background-repeat: no-repeat;
  background-size: contain;
  color: white;
  background: linear-gradient(0deg, rgba(3, 56, 143, 0.72), rgba(3, 56, 143, 0.72)), url("./static/img/bg.png") 50% 10%;
  z-index: 2;
  height: 100vh;
}

.blue-card {
  background: linear-gradient(95.2deg, #407bff 4.78%, #1e63ff 96.76%);
  border: 1px solid #fafafa;
  box-shadow: -1px -1px 8px rgba(64, 123, 255, 0.04), 2px 4px 8px -1px rgba(64, 123, 255, 0.04);
  border-radius: 24px;
}

.blue-grey {
  background-color: #EBF1FF;
}

#header {
  background: url("./static/img/bg.png") 0 0 no-repeat fixed;
  height: 100%;
  width: auto;
  overflow: hidden;
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: contain;
}

#client-header {
  background: url("./static/img/man_mother.png") no-repeat;
  height: 100%;
  width: 50vw;
  overflow: hidden;
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
}

#client-register {
  background: url("./static/img/chess.png") 0 0 no-repeat;
  height: 100%;
  width: 50vw;
  overflow: hidden;
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
}

.row {
  background: linear-gradient(0deg, rgba(3, 56, 143, 0.72), rgba(3, 56, 143, 0.72));
  overflow: hidden;
  z-index: 2;
}

.approved {
  background: rgba(50, 250, 50, 0.24);
  color: #29CC29;
}

.pending {
  color: #FAA700;
  background: rgba(250, 223, 50, 0.24);
}

.upcoming {
  background: rgba(255, 12, 9, 0.24);
  color: #FF0C09;
}

.bl {
  background: #011433;
}

.light-white {
  background: #FAFAFA;
}

.conversion-text {
  color: #327EFA;
  font-family: 'Patrick Hand', cursive;
}

.mint--green {
  background: #EEFFCC;
}

.dark--blue__text {
  color: #011433;
}

.bg-black--gradient {
  background: linear-gradient(99.46deg, #011433 0%, rgba(0, 15, 40, 0.97) 100%);
}

.mint--text {
  color: #DCF2B0;
}

.gray__text {
  color: #455564;
}

.mint-bg {
  background: #DCF2B0;
}

.teal-bg {
  background: #C2F8FF;
}